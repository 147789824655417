// import arr from "./huy1.json";
const arr = [
  "M113,111 113,111 113,112 113,113 115,116 117,118 118,120 120,123 122,126 124,129 126,131 131,138 137,144 139,147 142,152 145,155 148,159 151,162 154,166 157,170 160,174 163,177 165,181 168,184 171,187 173,191 176,193 178,196 180,198 183,201 185,203 187,205 190,208 192,210 195,213 198,215 200,218 202,220 205,222 206,223 210,227 211,229 215,232 217,234 218,236 222,240 223,241 225,243 227,245 229,247 231,249 232,251 234,252 235,254 237,255 238,257 240,259 243,262 244,263 245,264 247,267 248,268 249,268 250,269 251,270 251,271 252,272 253,272 253,273 254,273 255,274 255,275 256,275 257,276 257,276 257,276 258,277 258,277 258,277 258,277 258,277",
  "M100,285 100,285 101,282 103,280 105,278 106,276 107,275 108,273 110,271 112,269 114,267 115,264 117,261 119,259 121,256 123,254 124,251 127,248 129,245 131,242 134,239 137,236 139,232 142,230 145,226 146,225 150,220 151,219 154,214 157,212 159,209 161,207 163,204 165,202 168,200 170,197 172,194 173,193 177,189 178,188 180,186 183,183 185,182 189,177 191,175 193,173 195,171 197,169 202,165 207,161 209,159 211,157 214,155 216,153 219,151 220,150 222,147 224,146 225,144 228,142 229,140 231,138 232,137 234,135 236,134 237,132 238,131 240,129 241,128 243,127 244,126 245,125 247,124 248,123 249,122 250,121 251,120 252,120 252,119 253,118 254,118 254,118 255,117 255,117 256,116 256,116 257,115 257,115 258,114 259,114 259,114 260,113 261,113 263,112 264,112 265,111 266,111 267,110 268,109 269,109 269,109 269,109 270,108 270,108",
  "M110,110 111,110 112,111 113,113 115,116 117,118 117,119 119,121 122,124 125,127 126,129 128,130 129,131 130,132 132,134 133,135 135,137 136,139 138,140 139,142 141,143 142,144 143,146 145,147 146,148 147,150 148,151 149,152 150,153 152,155 153,156 154,157 155,158 156,159 157,161 158,162 159,163 160,164 161,165 162,166 163,167 164,168 164,169 165,171 166,171 166,172 166,172 167,173 168,175 168,175 169,176 169,177 170,178 171,180 171,181 172,181 172,182 172,183 173,184 173,185 174,186 174,187 175,187 175,188 175,188 175,189 176,190 176,191 177,191 177,192 177,193 178,193 178,194 178,195 179,195 179,195 180,196 180,197 180,197 181,198 181,198 181,198 182,199 182,200 183,200 183,201 183,201 184,202 184,202 184,202 185,203 185,203 185,203 186,204 186,204 187,204 187,205 187,205 188,205 188,206 188,206 188,206 189,207 189,207 189,207 189,207",
  "M270,110 270,110 270,111 269,112 268,112 267,114 266,116 265,118 264,119 263,120 262,122 260,124 258,127 257,128 255,129 253,132 253,133 251,135 249,137 248,138 247,140 245,142 244,143 243,145 242,147 241,148 240,150 239,151 238,153 236,154 235,156 234,158 233,159 231,161 230,163 229,164 227,166 226,168 225,169 223,171 221,173 220,174 218,176 217,177 215,180 214,181 212,183 211,184 210,186 208,188 207,189 206,191 205,192 204,193 202,195 201,197 200,198 199,199 197,201 196,202 194,203 193,205 192,206 191,207 189,208 188,209 186,211 184,214 183,215 182,216 181,217 180,219 179,220 177,221 176,223 175,224 174,225 173,227 172,228 171,229 170,230 169,231 168,232 167,234 166,235 166,235 165,236 164,237 163,238 162,239 162,239 161,240 161,241 160,242 159,243 158,244 157,245 157,246 156,246 155,247 155,248 154,249 153,250 153,251 152,251 152,252 151,253 150,254 149,255 149,256 148,256 146,258 146,259 145,260 145,261 144,262 143,263 142,264 142,264 141,265 140,266 140,266 139,267 139,268 138,269 138,269 137,270 136,271 136,272 135,272 135,273 134,273 134,274 133,274 133,275 132,275 132,275 131,276 131,276 131,276 131,276 130,277 130,277 130,277 129,277 129,278 129,278 128,278 128,278 127,279 127,279 127,279 126,279 125,280 124,280 123,280 122,281 121,281 120,281 119,281 119,281 118,281 117,281 116,281 114,280 113,278 112,277 111,277 110,276 109,275 109,274 108,273 108,272 107,271 107,270 106,270 106,269 106,269 106,268 106,268 106,268 106,268 106,268 106,268",
  "M110,111 110,110 110,111 110,112 110,113 110,115 110,116 110,118 110,120 110,123 110,125 110,128 110,131 110,134 110,137 110,141 110,144 110,147 110,150 110,153 110,156 110,159 110,162 110,165 110,168 110,171 110,173 110,176 110,179 111,182 111,184 111,188 112,190 112,195 112,201 113,203 113,205 113,207 113,210 113,214 114,216 114,218 115,219 115,221 115,223 115,224 115,226 116,228 116,230 116,231 116,233 117,235 117,236 117,238 117,239 117,241 117,242 117,243 117,244 117,246 117,247 117,248 117,249 117,250 117,251 117,252 117,253 117,255 117,256 117,257 117,258 117,259 117,260 117,261 117,263 117,264 117,265 117,265 117,266 117,267 117,268 117,268 117,269 117,270 117,270 117,270 117,271 117,271 117,271 117,271 117,272 117,272 116,273 116,274 116,275 116,276 116,277 115,278 115,279 115,280 115,280 115,281 115,281 115,281 115,281 115,281 115,281 114,280 114,280",
  "M114,280 114,280 114,279 114,278 115,276 118,273 120,268 121,266 123,263 124,261 126,259 129,254 131,251 133,249 135,246 137,243 139,240 142,237 143,236 146,231 148,229 151,226 153,224 155,221 158,218 160,216 162,213 165,210 168,208 170,206 172,203 173,202 176,199 178,197 179,195 181,194 184,190 185,189 188,186 189,185 192,181 193,179 195,177 196,176 199,172 200,171 202,169 203,167 205,165 208,161 210,160 211,159 212,157 214,156 217,153 219,150 220,149 221,148 224,145 226,143 227,141 228,140 229,140 230,139 231,138 232,137 233,137 234,136 235,135 235,134 236,134 237,133 238,132 239,131 240,130 240,130 241,129 242,129 242,128 243,128 243,127 244,126 244,126 245,125 245,124 246,124 246,123 247,122 248,122 248,121 249,120 250,119 250,119 251,118 251,117 252,116 252,116 253,115 254,114 254,114 255,113 256,111 258,110 258,110 259,109 260,109 260,108 260,107 261,107 261,107 261,106 262,106 262,106 262,106 263,106 263,106 263,106 263,106",
  "M262,106 262,106 262,108 262,110 262,112 262,114 262,117 262,120 262,123 262,126 262,131 262,135 262,139 262,144 262,148 262,152 262,157 262,160 262,164 262,167 262,172 262,175 262,179 262,182 262,186 261,190 260,194 260,195 259,200 259,202 258,205 258,207 257,210 257,212 257,214 256,216 256,218 256,220 255,222 255,224 255,226 254,228 254,231 253,235 252,237 252,239 251,242 251,245 251,247 251,248 251,249 250,250 250,251 250,252 250,253 250,254 250,254 250,255 250,256 250,257 250,258 250,259 250,260 250,260 250,261 250,262 250,262 250,263 250,264 250,264 250,265 250,265 250,266 250,266 250,267 250,267 250,268 250,268 250,269 250,270 250,270 250,271 250,272 250,272 250,273 250,273 250,274 250,275 250,275 250,275 250,275 250,275 250,274 250,274",
  "M160,77 160,77 160,77 160,78 160,78 160,79 160,79 160,80 160,80 161,80 161,81 162,81 162,82 163,82 164,83 166,83 167,84 168,84 170,85 172,85 174,86 175,86 177,87 180,87 181,88 183,88 185,88 188,89 190,89 192,89 193,89 195,89 198,89 199,89 200,89 202,89 203,89 204,88 205,88 206,87 207,87 209,85 211,84 212,83 213,82 214,81 215,80 215,79 216,78 217,77 217,77 218,76 218,75 218,75 219,75 219,74 219,74 219,74 219,74",
];

const root = document.getElementById("root");

root.innerHTML = `<svg preserveAspectRatio="xMidYMid meet" width="98vmin" height="98vmin" viewBox="0 0 375 375" style="background: black" stroke-linecap="round">
<path id="path" stroke="white" stroke-width="8" d=""></path>
</svg>`;

const path = document.getElementById("path");

const loop = (fn, from, ms) => {
  window.requestAnimationFrame((t) => {
    const n = Date.now();
    if (n - from < ms) {
      return loop(fn, from, ms);
    } else {
      fn();
    }
  });
};

const draw = (i) => {
  const now = Date.now();
  path.setAttribute("d", arr[i]);
  const newI = (i + 1) % arr.length;
  loop(() => draw(newI), now, 100);
};

draw(0);
